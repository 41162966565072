import React, {Suspense} from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import DownloadButtonsSection from './common/DownloadButtonsSection'
import AutoPlayInlineVideoContainer from './common/AutoPlayInlineVideoContainer'
import PieChartInsightSection from './common/PieChartInsightSection'
import LineGraph from './common/LineGraph'
import NeuroFitProgressRing from './common/NeuroFitProgressRing'
import MembershipFeatureDescription from './common/MembershipFeatureDescription'
import AppReviewPanel from './common/AppReviewPanel'
import UniversitySourcesSection from './common/UniversitySourcesSection'
import FaqSection from './common/FaqSection'
import RedeemAppResultModal from './modals/RedeemAppResultModal'
import ScrollAnimatedHeroImageContainer from './common/ScrollAnimatedHeroImageContainer'
import SendRequestButton from './common/SendRequestButton'
import Helmet from "./common/Helmet";
import ConfirmSection from './common/ConfirmSection'

import { history } from '../history'

import {v4 as uuidv4} from 'uuid'

import agent from '../agent'

import {
  subdomainUrls,
  recordSignupFunnelEventGA,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  ProductionComplimentaryAppAccessUrl,
  delay,
  DefaultAspectRatio,
  recordLinkClickAndRedirect,
  recordPixelEventIfAvailable,
  getCurrentYear,
  MinDesktopWidthPixels,
  initializePixelIfAvailable,
  isProduction,
  onceEventListener,
  getAffiliateCodeFromQueryString,
  getBrandKeyFromQueryString,
  getAppPromotionActiveFromQueryString,
  sanitizeEmailAddress,
  getTimeZoneOffset,
} from '../utils'

import {
  AppleSvg,
  AndroidSvg,
  HamburgerSvg,
  NeuroFitNamedLogoLinkSvg,
  VerifiedSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_WORKSHOP_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_BUTTON,
  GA_LABEL_CLICKED_COURSE_BUTTON,
  GA_LABEL_CLICKED_N_PLUS_BUTTON,
  GA_LABEL_CLICKED_QUIZ_BUTTON,
} from '../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../constants/requestStates';

import {
  EXPERIMENT_FLAGS_WEB_APP_SUBSCRIPTION_PRICING,
} from '../constants/experimentFlags'

import StarRatings from 'react-star-ratings';

import loadable from '@loadable/component'

const MobileAppTestimonials = loadable(() => import('./common/MobileAppTestimonials'))

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const YcCompanyDirectoryUrl = "https://www.ycombinator.com/companies"

const LandingPageFAQItems = [
  {
    key: "redeem_header",
    is_header: true,
    text: "Redeeming Access"
  },
  {
    key: "membership_offer_recipients",
    question: "What is being offered and to who?",
    answer: "Residents of LA who have been directly impacted by the wildfires will receive 2 months of complimentary app access."
  },
  {
    key: "membership_logistics",
    question: "How do I redeem app access?",
    answer: "Just enter your name and business email. You'll be asked to confirm your residence in LA, and a member of our team will then send instructions to redeem your free app access."
  },
  {
    key: "background_science_header",
    is_header: true,
    text: "Logistics & Science"
  },
  {
    key: "daily_commitment",
    question: "What is the daily time commitment for success?",
    answer: "Most members spend about 5 minutes a day inside the app. Beyond this, the recommended embodiment routine takes about 30 minutes each day (10 minutes each of social play, exercise, and stillness)."
  },
  {
    key: "science",
    question: "What is the data + science behind NEUROFIT?",
    answer: (
      <span>
        <span>{"NEUROFIT is data-driven and grounded in neuroscience. Our nervous system approach is validated by 7M+ in-app datapoints and supported by leading neuroscience research."}</span>
      </span>
    )
  },
  {
    key: "hrv_methodology",
    question: "How does your HRV measurement tech work?",
    answer: "To measure HRV, NEUROFIT reads changes in finger color via your phone's camera, to detect naturally occurring changes in heartbeat rhythm. This clinically validated technique is known as \"photoplethysmography\", and achieves a real-world accuracy of 96% compared to gold-standard (ECG-comparable) sensors in the appropriate conditions (eg, lighting, exposure, and finger pressure)."
  },
  {
    key: "balance_score",
    question: "How should I interpret my daily BALANCE score?",
    answer: "The NEUROFIT Balance Score is a percentage measuring the nervous system's overall flexibility and readiness to be challenged. It's a short-term indicator of nervous system strength, resilience and recovery on a given day, and is measured relative to other nervous system readings you've taken lately."
  }
]

let defaultShowAnswer = {}

LandingPageFAQItems.map(key => defaultShowAnswer[key] = false)


const ProductPhoneImagePaths = [
  "/assets/images/onboarding/devices/phone/en/six-week-program.webp",
  "/assets/images/onboarding/devices/phone/en/balance-exercises.webp",
  "/assets/images/onboarding/devices/phone/en/hrv-results.webp",
  "/assets/images/onboarding/devices/phone/en/insights.webp",
  "/assets/images/onboarding/devices/phone/en/clear-coaching.webp"
]

class WebAppCheckoutPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showAnswer: defaultShowAnswer,
      showSettingsModal: false,
      showQrCodeModal: false,
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      pageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#FFFFFF",
      show: true,
      showIframe: false,
      isVideoLoaded: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      hasScrolled: false,
      showResultModal: false,
      redeemAccessSuccess: false,
      heroBalanceImageLoaded: false,
      heroInsightsImageLoaded: false,
      currentStepIndex: 0,
      currentStepProgress: 0,
      isDiscountLink: getAppPromotionActiveFromQueryString(window.location),
      firstName: "",
      emailAddress: "",
      password: "",
      redeemAppAccessRequestState: REQUEST_UNSTARTED,
      deviceUuid: uuidv4(),
      timeZoneConfig: getTimeZoneOffset(),
      userHasConfirmedImpact: false
    }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const pageScrollProgress = Math.max((e.target.scrollTop / innerHeight), 0.0)
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(255, 255, 255, 1)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`
      const hasScrolled = true

      const productDiv = document.getElementById("transparent-product-div")
      const productScrollProgress = Math.max(((e.target.scrollTop - productDiv.offsetTop) / innerHeight * 1.05), 0.0)

      const currentStepIndex =  Math.min(Math.floor(productScrollProgress), ProductPhoneImagePaths.length + 1)
      const currentStepProgress = productScrollProgress % 1

      this.setState({headerBackgroundColor, currentStepIndex, currentStepProgress, pageScrollProgress, heroOverlayColor, dropShadowColor, hasScrolled, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity})
    } else {
      this.setState({headerBackgroundColor: "transparent", currentStepIndex: 0, currentStepProgress: 0, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#FFFFFF", headerBackgroundBlur: "none", topPageScrollProgress: 0, pageScrollProgress: 0, hasScrolled: true, headerBorderColor: "transparent", videoOpacity: 1})
    }
  }

  async triggerRedeemAppAccess() {
    const {
      firstName,
      emailAddress,
      password,
      deviceUuid,
      timeZoneConfig,
    } = this.state

    // this.setState({...this.state, redeemAppAccessRequestState: REQUEST_SUCCESS, redeemAccessSuccess: true, showResultModal: true})

    this.setState({redeemAppAccessRequestState: REQUEST_FETCHING})

    try {
      const body = {
        first_name: firstName,
        email_address: emailAddress,
        password: password,
        device_uuid: deviceUuid,
        tz: timeZoneConfig
      }
      const trainingRequestResult = await agent.NeuroFitBackend.redeemPremiumAppAccessWeb(body)
      this.setState({...this.state, redeemAppAccessRequestState: REQUEST_SUCCESS, redeemAccessSuccess: true, showResultModal: true})
    } catch (err) {
      this.setState({...this.state, redeemAppAccessRequestState: REQUEST_ERROR, redeemAccessSuccess: false, showResultModal: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isIOSDevice,
      isAndroidDevice,
      experimentFlags
    } = this.props

    const {show, showAnswer, userHasConfirmedImpact, firstName, emailAddress, password, redeemAppAccessRequestState, isDiscountLink, currentStepIndex, currentStepProgress, learnMoreRef, heroInsightsImageLoaded, heroBalanceImageLoaded, showResultModal, redeemAccessSuccess, showSettingsModal, showQrCodeModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, isHeroImageLoaded, logoOutlineColor, dropShadowColor, topPageScrollProgress, pageScrollProgress, showIframe, isVideoLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, hasScrolled} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const phoneAspectRatio = 880 / 432

    const heroVideoSizeWidthPixels = Math.min(Math.min(700, innerWidth * 0.7), innerHeight * 0.6 / phoneAspectRatio)
    const heroVideoSizeHeightPixels = innerHeight * 0.6

    const isMobile = innerWidth <= MinDesktopWidthPixels


    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    const currentYear = getCurrentYear()

    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    const isRedeemingAppAccess = (redeemAppAccessRequestState === REQUEST_FETCHING)
    const hasRedeemedAppAccess = (redeemAppAccessRequestState === REQUEST_SUCCESS)
    const formFieldsDisabled = isRedeemingAppAccess || hasRedeemedAppAccess
    const isRedeemAppAccessButtonDisabled = (!(firstName) || !(emailAddress) || (password.length < 8) || (!userHasConfirmedImpact) || isRedeemingAppAccess || hasRedeemedAppAccess)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"Complimentary App Access | NEUROFIT"}
          description={"Redeem complimentary access to the NEUROFIT App."}
          href={ProductionComplimentaryAppAccessUrl}
          useNoindex
        />
        <div style={{position: "fixed", top: 0, textAlign: "start", width: "100vw", backgroundColor: "#000000", height: innerHeight, zIndex: -1}}>
          <div style={{color: "black", opacity: 1, marginTop: `calc(60px + 3vh)`, display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
            <div style={{height: heroVideoSizeHeightPixels, maxWidth: heroVideoSizeWidthPixels}}>
              <ScrollAnimatedHeroImageContainer
                featureImagePaths={ProductPhoneImagePaths}
                widthPixels={heroVideoSizeWidthPixels}
                aspectRatio={phoneAspectRatio}
                heroImagePath={"/assets/images/onboarding/devices/phone/en/insights.webp"}
                currentImageIndex={Math.min(Math.max(currentStepIndex, 0), 5)}
                currentImageProgress={currentStepProgress}
              />
            </div>
          </div>
          {(currentStepIndex === 0) && (
            <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", opacity: (currentStepProgress > 0 && currentStepProgress < 1) ? 1 : 0, transition: "opacity 200ms ease-in"}}>
              <div style={{width: "85%", color: "#EDEEF2", maxWidth: 500}}>
                <div style={{display: "flex", height: "100%", width: "100%", marginTop: "6vh", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{fontSize: "min(6vw, 26px)", color: "#a5a8b0", fontFamily: "Futura", opacity: (1.1 - (Math.abs(currentStepProgress - 0.5) * 2.0)), transform: `translateY(${-40 * (currentStepProgress - 0.5)}px)`}}>
                    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium"}}>{"Balance your nervous system with NEUROFIT to feel and perform your best:"}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(currentStepIndex === 1) && (
            <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", opacity: (currentStepProgress > 0 && currentStepProgress < 1) ? 1 : 0, transition: "opacity 200ms ease-in"}}>
              <div style={{width: "85%", color: "#EDEEF2", maxWidth: 500}}>
                <div style={{display: "flex", height: "100%", width: "100%", marginTop: "6vh", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{fontSize: "min(6vw, 26px)", color: "#a5a8b0", fontFamily: "Futura", opacity: (1.1 - (Math.abs(currentStepProgress - 0.5) * 2.0)), transform: `translateY(${-40 * (currentStepProgress - 0.5)}px)`,}}>
                    <span>{"The "}</span>
                    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium"}}>{" guided nervous system program "}</span>
                    <span>{"is designed for highly effective + simple stress relief."}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(currentStepIndex === 2) && (
            <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", opacity: (currentStepProgress > 0 && currentStepProgress < 1) ? 1 : 0, transition: "opacity 200ms ease-in"}}>
              <div style={{width: "85%", color: "#EDEEF2", maxWidth: 500}}>
                <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{fontSize: "min(6vw, 26px)", color: "#a5a8b0", marginTop: "6vh", fontFamily: "Futura", opacity: (1.1 - (Math.abs(currentStepProgress - 0.5) * 2.0)), transform: `translateY(${-40 * (currentStepProgress - 0.5)}px)`,}}>
                    <span>{"The "}</span>
                    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium"}}>{" somatic exercise library "}</span>
                    <span>{"matches you with exercises that relieve stress in just 2-3 minutes."}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(currentStepIndex === 3) && (
            <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", opacity: (currentStepProgress > 0 && currentStepProgress < 1) ? 1 : 0, transition: "opacity 200ms ease-in"}}>
              <div style={{width: "85%", color: "#EDEEF2", maxWidth: 500}}>
                <div style={{display: "flex", height: "100%", width: "100%", marginTop: "6vh", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{fontSize: "min(6vw, 26px)", color: "#a5a8b0", fontFamily: "Futura", opacity: (1.1 - (Math.abs(currentStepProgress - 0.5) * 2.0)), transform: `translateY(${-40 * (currentStepProgress - 0.5)}px)`,}}>
                    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium"}}>{"Camera-based HRV tech"}</span>
                    <span>{" lets you measure progress and recovery without a wearable."}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(currentStepIndex === 4) && (
            <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", opacity: (currentStepProgress > 0 && currentStepProgress < 1) ? 1 : 0, transition: "opacity 200ms ease-in"}}>
              <div style={{width: "85%", color: "#EDEEF2", maxWidth: 500}}>
                <div style={{display: "flex", height: "100%", width: "100%", marginTop: "6vh", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{fontSize: "min(6vw, 26px)", color: "#a5a8b0", fontFamily: "Futura", opacity: (1.1 - (Math.abs(currentStepProgress - 0.5) * 2.0)), transform: `translateY(${-40 * (currentStepProgress - 0.5)}px)`,}}>
                    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium"}}>{"Personalized insights"}</span>
                    <span>{" help you feel better faster, by pinpointing what your body needs most."}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(currentStepIndex === 5) && (
            <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center", opacity: (currentStepProgress > 0 && currentStepProgress < 1) ? 1 : 0, transition: "opacity 200ms ease-in"}}>
              <div style={{width: "85%", color: "#EDEEF2", maxWidth: 500}}>
                <div style={{display: "flex", height: "100%", width: "100%", marginTop: "6vh", flexDirection: "column", justifyContent: "space-between"}}>
                  <div style={{fontSize: "min(6vw, 26px)", color: "#a5a8b0", fontFamily: "Futura", opacity: (1.1 - (Math.abs(currentStepProgress - 0.5) * 2.0)), transform: `translateY(${-40 * (currentStepProgress - 0.5)}px)`}}>
                    <span>{"The "}</span>
                    <span style={{color: "#FFFFFF", fontFamily: "Futura Medium"}}>{" AI breakthrough coach "}</span>
                    <span>{"helps you clear stressful mental blocks in a single conversation."}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoLinkSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
              href={"/"}
            />
          </div>
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: showSettingsModal ? "rgba(0, 0, 0, 0.5)" : "transparent", pointerEvents: "none", transition: "all 300ms ease-out", transitionProperty: "background-color", zIndex: 2000}}>
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={{...containerStyle, position: "fixed", left: 0, top: 0, height: innerHeight, right: 0}}
          > 
            <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 3vh) 0px", backgroundColor: "#EDEEF2"}} ref={elem => this.learnMoreRef = elem}>
              <div style={{height: "100%"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                      {"Wellness Built For"}
                    </div>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                      {"The Unexpected"}
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                      <img
                        src={`/images/hero-phone-balance-${isMobile ? "mobile" : "desktop"}.webp`}
                        fetchpriority="high"
                        style={{height: "50vh", width: "calc(50vh * 0.579)", zIndex: 1, opacity: heroBalanceImageLoaded ? 1.0 : 0, transition: "opacity 0.4s ease-out", margin: "3vh 0px", transform: `translateX(calc(-1 * ${pageScrollProgress - 0.6}  * 5vh))`}}
                        onLoad={() => {
                          this.setState({heroBalanceImageLoaded: true})
                        }}
                      />
                      <img
                        src={`/images/hero-phone-insights-${isMobile ? "mobile" : "desktop"}.webp`}
                        fetchpriority="high"
                        style={{height: "calc(50vh * 0.896)", width: "calc(50vh * 0.896 * 0.579)", zIndex: 0, opacity: heroInsightsImageLoaded ? 1.0 : 0, transition: "opacity 0.4s ease-out 0.4s", margin: "calc(3vh + calc(50vh * 0.052)) 0px 3vh 0px", transform: `translateX(calc(${pageScrollProgress - 0.6} * 5vh))`}}
                        onLoad={() => {
                          this.setState({heroInsightsImageLoaded: true})
                          initializePixelIfAvailable()
                        }}
                      />
                    </div>
                    <div>
                      <div style={{display: "flex", justifyContent: "center", marginTop: "3vh", pointerEvents: "none"}}>
                        <img src={isMobile ? "/images/media-logos-6-mobile-black.webp?v=5" : "/images/media-logos-6-desktop-black.webp?v=5"} fetchpriority="high" style={{width: "100%", maxWidth: 500, height: "calc(min(85vw, 500px) / 4.3634)", opacity: 0.55}} alt="NEUROFIT has been featured in SHAPE, Well and Good, and Body and Soul." />
                      </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "2vh", pointerEvents: "none"}}>
                      <svg onClick={() => this.superiorResultsRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#444444"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: "7vh 0vh", backgroundColor: "#000000", display: "flex", width: "100%", justifyContent: "center"}}>
              <div style={{width: "85%", maxWidth: 400}}>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)"}}>
                  {"Dear Los Angeles:"}
                </div>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginTop: "2vh", fontStyle: "italic"}}>
                  {"As ex-LA founders who have lived through Complex PSTD and acute tragedy, it's been very challenging seeing everything go down in LA lately."}
                </div>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginTop: "2vh", fontStyle: "italic"}}>
                  {"We're offering complimentary access to NEUROFIT's high-performance stress relief program for a limited time."}
                </div>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginTop: "2vh", fontStyle: "italic"}}>
                  {"Our average active user reduces stress by 54% after just 7 days - and we trust that these tools can provide some support during this challenging time."}
                </div>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginTop: "2vh", fontStyle: "italic"}}>
                  {"Wishing you and your loved ones safety and peace,"}
                </div>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginTop: "2vh", fontStyle: "italic"}}>
                  {"Loren + Andrew Hogue"}
                </div>
                <div style={{color: "#FFFFFF", fontFamily: "Futura", fontSize: "min(6vw, 26px)", marginTop: "1vh", fontStyle: "italic"}}>
                  {"Co-CEO's, NEUROFIT"}
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid", borderBottom: "2px #000000 solid"}} ref={elem => this.learnMoreRef = elem}>
              <div style={{height: "100%"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                      {"Backed By Data."}
                    </div>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                      {"Trusted By Leading Health Experts."}
                    </div>
                    <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{`NEUROFIT is designed to improve well-being fast: on average, active users report `}</span>
                      <span style={{fontFamily: "Futura"}}>{`54% less stress after 1 week.`}</span>
                    </div>
                    <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span style={{fontFamily: "Futura"}}>{`Hundreds of therapists, doctors, health coaches, and more`}</span>
                      <span>{` use this nervous system regulation app with their clients.`}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{textAlign: "center", margin: "5vh auto 2vh auto", opacity: 1.0}}>
                      <div>
                        <StarRatings
                          disabled
                          rating={4.8}
                          starRatedColor={"#000000"}
                          starEmptyColor={"#CFD2DD"}
                          starDimension={"min(8vw, 40px)"}
                          starSpacing={"min(2vw, 10px)"}
                          numberOfStars={5}
                        />
                      </div>
                      <div style={{fontSize: "min(5vw, 26px)", textAlign: "center", fontFamily: "Futura Medium", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                        {"4.8/5 App Store Rating"}
                      </div>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                        <VerifiedSvg
                          color={"#a5a8b0"}
                          size={"min(4.5vw, 18px)"}
                        />
                        <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#a5a8b0", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                          {"100,000+ Users"}
                        </div>
                      </div>
                      <div style={{marginTop: 10, fontSize: "min(4vw, 18px)", textAlign: "center", color: "#a5a8b0", fontFamily: "Futura Medium", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                        {"Available on iOS + Android"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="transparent-product-div"
              style={{backgroundColor: "transparent", height: innerHeight * 1.05 * (ProductPhoneImagePaths.length + 1)}}
            >

            </div>
            <div style={{textAlign: "start", width: "100vw", minHeight: `calc(${innerHeight}px - 120px - 10vh)`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", padding: `calc(60px + 5vh) 0px`}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"Better Emotional + Mental Health In Just A Few Days"}
                  </div>
                  <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <div style={{marginTop: "6vh"}}>
                      <span>{"Chronic stress imbalances the nervous system. Mindset falls short here - only body-based methods can resolve this:"}</span>
                    </div>
                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                      <div style={{width: "100%", maxWidth: 450}}>
                        <PieChartInsightSection
                          title={`EMBODIMENT VS. MINDSET`}
                          marginTop={"max(3vw, 16px)"}
                          disableDropDown
                          dataIsAvailable
                          data={{"embodiment": 80, "mindset": 20}}
                          insightText={`NEUROFIT's embodiment approach speaks to 80% of the nervous system. Mindset only speaks to the other 20%.`}
                          dataCategories={[{title_name: "Embodiment", name: "embodiment", color: "#FFD634"}, {title_name: "Mindset", name: "mindset", color: "#9EA0DA"}]}
                        />
                      </div>
                    </div>
                    <div style={{marginTop: "8vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span style={{fontFamily: "Futura"}}>{"Nervous System Fitness"}<sup>{"®"}</sup></span>
                      <span>{" is designed to improve holistic well-being quickly, and reduce overwhelm in days: "}</span>
                    </div>
                    <div style={{display: "flex", marginTop: "2vh", width: "100%", justifyContent: "center"}}>
                      <div style={{backgroundColor: "#FFFFFF", width: "100%", maxWidth: 450, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                        <div style={{padding: "min(24px, max(3vw, 16px))"}}>
                          <div style={{fontSize: "min(5vw, 22px)", textAlign: "start", fontFamily: "Futura Medium"}}>
                            {"NERVOUS SYSTEM FITNESS:"}
                          </div>
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Quickly Reduces Stress + Anxiety"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Enhances Focus + Creativity"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Improves Emotional + Mental Clarity"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Optimizes Sleep + Accelerates Recovery"}
                          />
                          <MembershipFeatureDescription
                            fontSize={"min(4vw, 18px)"}
                            fontFamily={"Futura"}
                            checkMarginTop={"0.15em"}
                            text={"Boosts Immunity + Digestion"}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{marginTop: "8vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{"Within just 5 minutes of NEUROFIT exercises, 95% of members report relief from real-time stress:"}</span>
                    </div>
                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                      <div style={{width: "100%", maxWidth: 450}}>
                        <LineGraph
                          dataIsAvailable
                          title={`REAL-TIME STRESS REDUCTION`}
                          data={{
                            labels: ["0", "1", "2", "3"],
                            series: [[100, 11.2, 5, 1.4]],
                          }}
                          labelInterpolationFnc={(val) => `${val}%`}
                          highYValue={100}
                          hideLogo
                          xAxisTitle={"Exercises Completed (2-3 mins. each)"}
                        />
                      </div>
                    </div>
                    <div style={{marginTop: "8vh", marginBottom: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                      <span>{`The NEUROFIT Method is validated by `}</span>
                      <span style={{fontFamily: "Futura"}}>{`7M+ in-app datapoints`}</span>
                      <span>{`, and designed bottom-up with `}</span>
                      <span style={{fontFamily: "Futura"}}>{"neuroscience research"}</span>
                      <span>{" from leading universities."}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0vh", backgroundColor: "#000000", borderTop: "2px #000000 solid"}}>
              <div style={{color: "#FFFFFF", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8vw, 40px)", textAlign: "start"}}>
                    {"Your Personal High-Performance Stress Relief Coach"}
                  </div>
                  <div style={{display: "flex", marginTop: "3vh", width: "100%", justifyContent: "center"}}>
                    <div style={{maxWidth: "min(100%, 600px)"}}>
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"Beat Stress in 5 Minutes With Tailored Exercises"}
                      />
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"Daily Check-Ins & Habit Tracking"}
                      />
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"Personalized Insights & Guidance"}
                      />
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"In-App HRV Measurement (no wearable needed)"}
                      />
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"Unlimited AI-Guided Breakthrough Coaching"}
                      />
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"Full Content Library (updated monthly)"}
                      />
                      <MembershipFeatureDescription
                        fontSize={"min(6vw, 26px)"}
                        fontFamily={"Futura"}
                        checkMarginTop={"0.35em"}
                        checkboxBaseClassName={"cert-page-plan-button-white"}
                        textColor={"#FFFFFF"}
                        checkColorComplete={"#000000"}
                        text={"Used by 2,000+ Health Professionals"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="checkout" style={{textAlign: "start", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid"}} ref={elem => this.checkoutRef = elem}>
              <div style={{width: "100%"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 500}}>
                    <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8vw, 40px)", textAlign: "start"}}>
                      {"LA Community: Get"}
                    </div>
                    <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8vw, 40px)", marginBottom: "2vh", textAlign: "start"}}>
                      {"High-Performance Stress Relief, On Us"}
                    </div>
                    <div style={{marginInlineEnd: "0.2em", marginBottom: "max(0.75vw, 4px)", marginBottom: "2vh", fontSize: "min(5.5vw, 22px)", fontFamily: "Futura"}}>
                      {"Reduce stress in minutes with somatic exercises and AI coaching sessions."}
                    </div>
                    <div style={{marginInlineEnd: "0.2em", marginBottom: "max(0.75vw, 4px)", fontSize: "min(5.5vw, 22px)", fontFamily: "Futura"}}>
                      {"Fill out the form below - our team will email you next steps for free access."}
                    </div>
                  </div>
                </div>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <div style={{width: "85%", maxWidth: 500}}>
                    <div style={{marginTop: "2vh"}}>
                      <input
                        id="neurofit-app-signup-first-name"
                        name="firstname"
                        type="text"
                        value={firstName}
                        disabled={formFieldsDisabled}
                        autoCapitalize="true"
                        autoCorrect="false"
                        autoComplete="given-name"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({firstName: e.target.value})
                        }}
                        onPaste={(e) => {
                          this.setState({firstName: e.target.value})
                        }}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter' && (!isRedeemAppAccessButtonDisabled)) {
                            this.triggerRedeemAppAccess()
                          }
                        }}
                        placeholder={"Your First Name"}
                        className={"application-input"}
                      />
                    </div>
                    <div style={{marginTop: "2vh", marginBottom: "2vh"}}>
                      <input
                        id="neurofit-app-signup-email-address"
                        name="email"
                        type="text"
                        value={emailAddress}
                        disabled={formFieldsDisabled}
                        autoCapitalize="none"
                        autoCorrect="false"
                        autoComplete="username"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({emailAddress: sanitizeEmailAddress(e.target.value)})
                        }}
                        onPaste={(e) => {
                          this.setState({emailAddress: sanitizeEmailAddress(e.target.value)})
                        }}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter' && (!isRedeemAppAccessButtonDisabled)) {
                            this.triggerRedeemAppAccess()
                          }
                        }}
                        placeholder={"Your Email Address"}
                        className={"application-input"}
                      />
                    </div>
                    <div style={{marginBottom: "2vh"}}>
                      <input
                        id="neurofit-app-signup-password"
                        type="password"
                        value={password}
                        disabled={formFieldsDisabled}
                        autoCapitalize="none"
                        autoCorrect="false"
                        autoComplete="current-password"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({password: e.target.value})
                        }}
                        onPaste={(e) => {
                          this.setState({password: e.target.value})
                        }}
                        placeholder={"Set a Password (8+ chars)"}
                        className={"application-input"}
                      />
                    </div>
                    <ConfirmSection
                      label={"I confirm that I'm an LA resident that is directly impacted by the 2025 fires"}
                      isConfirmed={userHasConfirmedImpact}
                      onToggleConfirm={(isConfirmed) => {
                        this.setState({userHasConfirmedImpact: isConfirmed})
                      }}
                      isDisabled={formFieldsDisabled}
                      marginTop={"0vh"}
                      marginBottom={"2vh"}
                      fontFamily={"Futura"}
                    />
                    <SendRequestButton
                      defaultContent={"Get Instant Access"}
                      loadingText={"Sending Invite..."}
                      successText={"Invite Sent"}
                      requestState={redeemAppAccessRequestState}
                      isDisabled={isRedeemAppAccessButtonDisabled}
                      dimButtonText={isRedeemAppAccessButtonDisabled && !isRedeemingAppAccess && !hasRedeemedAppAccess}
                      onClick={() => {
                        this.triggerRedeemAppAccess()
                      }}
                    />
                    <div style={{fontSize: "min(4vw, 18px)", color: "#999999", marginTop: "2vh"}}>
                      <span style={{marginInlineEnd: "0.2em", color: "#999999", fontFamily: "Futura Book"}}>{`By unlocking app access above, you agree to our platform`}</span>
                      <a style={{color: "#999999", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                      <span style={{marginInlineEnd: "0.2em", fontFamily: "Futura Book"}}>{" & "}</span>
                      <a style={{color: "#999999", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                      <span style={{fontFamily: "Futura Book"}}>{". Thank you!"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"Vetted By Leading Health Experts"}
                  </div>
                </div>
              </div>
              <MobileAppTestimonials />
            </div>
            <div style={{textAlign: "start", width: "100vw", backgroundColor: "#EDEEF2", paddingTop: `calc(60px + 5vh)`, borderTop: "2px #000000 solid"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingBottom: "calc(60px + 5vh)"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"FREQUENTLY ASKED"}
                  </div>
                  {LandingPageFAQItems.map((f) => (
                    (f.is_header ? (
                      <div key={f.key} style={{fontSize: "min(7vw, 32px)", textTransform: "uppercase", margin: "min(9vh, 45px) 0px 0px 0px", color: "#000000", textAlign: "start", fontFamily: "Futura"}}>
                        {f.text}
                      </div>
                    ) : (
                      <FaqSection
                        key={f.key}
                        question={f.question}
                        answer={f.answer}
                        showAnswer={showAnswer[f.key]}
                        onToggleShowAnswer={() => this.setState({showAnswer: {...showAnswer, [f.key]: (!showAnswer[f.key])}})}
                        marginTop={"2vh"}
                      />
                    ))
                  ))}
                </div>
              </div>
              <div style={{padding: 15, paddingTop: 30, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", color: "#c1c4d2", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {`© NEUROFIT ${currentYear}`}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.2em", fontFamily: "Futura Medium", fontSize: "min(4.5vw, 18px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RedeemAppResultModal
          show={showResultModal}
          firstName={firstName}
          emailAddress={emailAddress}
          isSuccessful={redeemAccessSuccess}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          isIOSDevice={isIOSDevice}
          isAndroidDevice={isAndroidDevice}
          isDesktop={!isMobile}
          onClose={() => this.setState({showResultModal: false})}
        />
      </div>
    )
  }
}

export default WebAppCheckoutPage;