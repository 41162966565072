import React from 'react';

import i18next from 'i18next'

import {
  initializeSentry,
  captureSentryException,
  ProductionBaseUrl,
  ProductionGlossaryBaseUrl,
} from './utils'

export const LanguageCodeArabic = "ar"
export const LanguageCodeCatalan = "ca"
export const LanguageCodeChinese = "zh"
export const LanguageCodeCroatian = "hr"
export const LanguageCodeCzech = "cs"
export const LanguageCodeDanish = "da"
export const LanguageCodeDutch = "nl"
export const LanguageCodeFinnish = "fi"
export const LanguageCodeFrench = "fr"
export const LanguageCodeGerman = "de"
export const LanguageCodeGreek = "el"
export const LanguageCodeEnglish = "en"
export const LanguageCodeHebrew = "he"
export const LanguageCodeHindi = "hi"
export const LanguageCodeHungarian = "hu"
export const LanguageCodeIndonesian = "id"
export const LanguageCodeItalian = "it"
export const LanguageCodeJapanese = "ja"
export const LanguageCodeKorean = "ko"
export const LanguageCodeMalay = "ms"
export const LanguageCodeNorwegian = "no"
export const LanguageCodePolish = "pl"
export const LanguageCodePortuguese = "pt"
export const LanguageCodeRomanian = "ro"
export const LanguageCodeRussian = "ru"
export const LanguageCodeSlovak = "sk"
export const LanguageCodeSpanish = "es"
export const LanguageCodeSwedish = "sv"
export const LanguageCodeThai = "th"
export const LanguageCodeTurkish = "tr"
export const LanguageCodeUkranian = "uk"
export const LanguageCodeVietnamese = "vi"
export const LanguageCodeBrazilianPortuguese = "pt-BR"
export const LanguageCodeMexicanSpanish = "es-MX"
export const LanguageCodeCanadianFrench = "fr-CA"
export const LanguageCodeTraditionalChinese = "zh-Hant"
const SupportedLanguageCodes = new Set([
  LanguageCodeArabic,
  LanguageCodeCatalan,
  LanguageCodeChinese,
  LanguageCodeCroatian,
  LanguageCodeCzech,
  LanguageCodeDanish,
  LanguageCodeDutch,
  LanguageCodeFinnish,
  LanguageCodeFrench,
  LanguageCodeGerman,
  LanguageCodeGreek,
  LanguageCodeEnglish,
  LanguageCodeHebrew,
  LanguageCodeHindi,
  LanguageCodeHungarian,
  LanguageCodeIndonesian,
  LanguageCodeItalian,
  LanguageCodeJapanese,
  LanguageCodeKorean,
  LanguageCodeMalay,
  LanguageCodeNorwegian,
  LanguageCodePolish,
  LanguageCodePortuguese,
  LanguageCodeRomanian,
  LanguageCodeRussian,
  LanguageCodeSlovak,
  LanguageCodeSpanish,
  LanguageCodeSwedish,
  LanguageCodeThai,
  LanguageCodeTurkish,
  LanguageCodeUkranian,
  LanguageCodeVietnamese,
  LanguageCodeBrazilianPortuguese,
  LanguageCodeMexicanSpanish,
  LanguageCodeCanadianFrench,
  LanguageCodeTraditionalChinese,
])

export const LocalizedLanguageObjects = [
  {
    "language_code": LanguageCodeArabic,
    "localized_name": "العربية"
  },
  {
    "language_code": LanguageCodeCatalan,
    "localized_name": "Català"
  },
  {
    "language_code": LanguageCodeChinese,
    "localized_name": "简体中文"
  },
  {
    "language_code": LanguageCodeTraditionalChinese,
    "localized_name": "繁體中文"
  },
  {
    "language_code": LanguageCodeCroatian,
    "localized_name": "Hrvatski"
  },
  {
    "language_code": LanguageCodeCzech,
    "localized_name": "Čeština"
  },
  {
    "language_code": LanguageCodeDanish,
    "localized_name": "Dansk"
  },
  {
    "language_code": LanguageCodeDutch,
    "localized_name": "Nederlands"
  },
  {
    "language_code": LanguageCodeFinnish,
    "localized_name": "Suomi"
  },
  {
    "language_code": LanguageCodeFrench,
    "localized_name": "Français"
  },
  {
    "language_code": LanguageCodeCanadianFrench,
    "localized_name": "Français (Canada)"
  },
  {
    "language_code": LanguageCodeGerman,
    "localized_name": "Deutsch"
  },
  {
    "language_code": LanguageCodeGreek,
    "localized_name": "Ελληνικά"
  },
  {
    "language_code": LanguageCodeEnglish,
    "localized_name": "English"
  },
  {
    "language_code": LanguageCodeHebrew,
    "localized_name": "עברית"
  },
  {
    "language_code": LanguageCodeHindi,
    "localized_name": "हिन्दी"
  },
  {
    "language_code": LanguageCodeHungarian,
    "localized_name": "Magyar"
  },
  {
    "language_code": LanguageCodeIndonesian,
    "localized_name": "Bahasa Indonesia"
  },
  {
    "language_code": LanguageCodeItalian,
    "localized_name": "Italiano"
  },
  {
    "language_code": LanguageCodeJapanese,
    "localized_name": "日本語"
  },
  {
    "language_code": LanguageCodeKorean,
    "localized_name": "한국어"
  },
  {
    "language_code": LanguageCodeMalay,
    "localized_name": "Bahasa Melayu"
  },
  {
    "language_code": LanguageCodeNorwegian,
    "localized_name": "Norsk"
  },
  {
    "language_code": LanguageCodePolish,
    "localized_name": "Polski"
  },
  {
    "language_code": LanguageCodePortuguese,
    "localized_name": "Português (Portugal)"
  },
  {
    "language_code": LanguageCodeBrazilianPortuguese,
    "localized_name": "Português (Brasil)"
  },
  {
    "language_code": LanguageCodeRomanian,
    "localized_name": "Română"
  },
  {
    "language_code": LanguageCodeRussian,
    "localized_name": "Русский"
  },
  {
    "language_code": LanguageCodeSlovak,
    "localized_name": "Slovenčina"
  },
  {
    "language_code": LanguageCodeSpanish,
    "localized_name": "Español (España)"
  },
  {
    "language_code": LanguageCodeMexicanSpanish,
    "localized_name": "Español (Latinoamérica)"
  },
  {
    "language_code": LanguageCodeSwedish,
    "localized_name": "Svenska"
  },
  {
    "language_code": LanguageCodeThai,
    "localized_name": "ภาษาไทย"
  },
  {
    "language_code": LanguageCodeTurkish,
    "localized_name": "Türkçe"
  },
  {
    "language_code": LanguageCodeUkranian,
    "localized_name": "Українська"
  },
  {
    "language_code": LanguageCodeVietnamese,
    "localized_name": "Tiếng Việt"
  }
]

// iOS only supports Norwegian Bokmal -- We map this to standard Norwegian for our localization purposes.
export const LanguageCodeNorwegianBokmal = "nb"
export const LanguageCodeNorwegianNyorsk = "nn"
export const LanguageCodeModernHebrew = "iw"
export const LanguageCodeIndonesianOriginal = "in"
const LanguageCodeMappings = {
  [LanguageCodeNorwegianBokmal]: LanguageCodeNorwegian,
  [LanguageCodeNorwegianNyorsk]: LanguageCodeNorwegian,
  [LanguageCodeModernHebrew]: LanguageCodeHebrew,
  [LanguageCodeIndonesianOriginal]: LanguageCodeIndonesian
}

export const SetGlobalI18N = async (updatedI18N, updatedLanguage, updatedCountryCode="") => {
  const countryCodeValid = ((!!(updatedCountryCode)) && (updatedCountryCode !== "-"))
  const isCountrySpecificLocale = updatedLanguage.includes("-")
  const languages = (countryCodeValid && (!isCountrySpecificLocale)) ? [`${updatedLanguage}-${updatedCountryCode}`, updatedLanguage] : [updatedLanguage]

  await i18next.init({
    lng: languages,
    // debug: true,
    resources: {
      [updatedLanguage]: {
        translation: updatedI18N
      }
    },
    saveMissing: true
  })

  // Set layout direction of document.
  document.dir = i18next.dir();

  await i18next.on('missingKey', async (lngs, namespace, key, res) => {
    const err = new Error(`Missing I18N Key: ${lngs} ${namespace} ${key}`)
    console.error(err)
    await initializeSentry()
    await captureSentryException(err)
  });
}

const LocaleSpecificLanguages = new Set([
  LanguageCodeSpanish,
  LanguageCodeFrench,
  LanguageCodePortuguese,
  LanguageCodeChinese
])

const LocaleCodeSpain = "ES"
const LocaleCodeCanada = "CA"
const LocaleCodeBrazil = "BR"
const LocaleCodeTaiwan = "TW"
const LocaleCodeTraditionalChinese = "Hant"

const getLocaleSpecificLanguageCode = (trimmedLanguageCode, rawLanguageTag) => {
  if (trimmedLanguageCode === LanguageCodeSpanish) {
    if (rawLanguageTag.includes(LocaleCodeSpain) || rawLanguageTag === LanguageCodeSpanish) {
      return LanguageCodeSpanish
    } else {
      return LanguageCodeMexicanSpanish
    }
  } else if (trimmedLanguageCode === LanguageCodeFrench) {
    if (rawLanguageTag.includes(LocaleCodeCanada)) {
      return LanguageCodeCanadianFrench
    } else {
      return LanguageCodeFrench
    }
  } else if (trimmedLanguageCode === LanguageCodePortuguese) {
    if (rawLanguageTag.includes(LocaleCodeBrazil)) {
      return LanguageCodeBrazilianPortuguese
    } else {
      return LanguageCodePortuguese
    }
  } else if (trimmedLanguageCode === LanguageCodeChinese) {
    if (rawLanguageTag.includes(LocaleCodeTraditionalChinese) || rawLanguageTag.includes(LocaleCodeTaiwan)) {
      return LanguageCodeTraditionalChinese
    } else {
      return LanguageCodeChinese
    }
  } else {
    return trimmedLanguageCode
  }
}

export const getValidatedLanguageCode = (rawLanguageCode, rawLanguageTag) => {
  const trimmedLanguageCode = rawLanguageCode.slice(0, 2)

  if (SupportedLanguageCodes.has(trimmedLanguageCode)) {
    if (LocaleSpecificLanguages.has(trimmedLanguageCode)) {
      return getLocaleSpecificLanguageCode(trimmedLanguageCode, rawLanguageTag)
    }
    return trimmedLanguageCode
  } else if (!!(LanguageCodeMappings[trimmedLanguageCode])) {
    return LanguageCodeMappings[trimmedLanguageCode]
  } else {
    return LanguageCodeEnglish
  }
}


export const local_i18n = {
  [LanguageCodeArabic]: {
    error_app_load_failed: "خطأ: فشل تحميل التطبيق.",
    retry: "أعد المحاولة",
    password_reset_link_invalid: "خطأ: انتهت صلاحية رابط إعادة تعيين كلمة المرور هذا أو أنه غير صالح.",
    get_another_link: "الحصول على رابط آخر"
  },
  [LanguageCodeCatalan]: {
    error_app_load_failed: "Error: la càrrega de l'aplicació ha fallat.",
    retry: "Torna-ho a provar",
    password_reset_link_invalid: "Error: aquest enllaç de restabliment de la contrasenya ha caducat o no és vàlid.",
    get_another_link: "Aconsegueix un altre enllaç"
  },
  [LanguageCodeChinese]: {
    error_app_load_failed: "错误：应用程序加载失败。",
    retry: "重试",
    password_reset_link_invalid: "错误：此密码重置链接已过期或无效。",
    get_another_link: "获取另一个链接"
  },
  [LanguageCodeTraditionalChinese]: {
    error_app_load_failed: "錯誤：應用程式載入失敗。",
    retry: "重試",
    password_reset_link_invalid: "錯誤：此密碼重設連結已過期或無效。",
    get_another_link: "獲取其他連結"
  },
  [LanguageCodeCroatian]: {
    error_app_load_failed: "Pogreška: aplikacija se nije uspjela učitati.",
    retry: "Pokušaj ponovo",
    password_reset_link_invalid: "Pogreška: ova veza za poništavanje lozinke je istekla ili nije važeća.",
    get_another_link: "Nabavite još jednu poveznicu"
  },
  [LanguageCodeCzech]: {
    error_app_load_failed: "Chyba: Aplikaci se nepodařilo načíst.",
    retry: "Zkuste to znovu",
    password_reset_link_invalid: "Chyba: Platnost tohoto odkazu pro resetování hesla vypršela nebo je neplatný.",
    get_another_link: "Získejte další odkaz"
  },
  [LanguageCodeDanish]: {
    error_app_load_failed: "Fejl: Appen blev ikke indlæst.",
    retry: "Prøve igen",
    password_reset_link_invalid: "Fejl: Dette link til nulstilling af adgangskode er udløbet eller er ugyldigt.",
    get_another_link: "Få et andet link"
  },
  [LanguageCodeDutch]: {
    error_app_load_failed: "Fout: De app kon niet worden geladen.",
    retry: "Opnieuw proberen",
    password_reset_link_invalid: "Fout: deze link voor het opnieuw instellen van het wachtwoord is verlopen of ongeldig.",
    get_another_link: "Vraag een andere link aan"
  },
  [LanguageCodeFinnish]: {
    error_app_load_failed: "Virhe: Sovelluksen lataaminen epäonnistui.",
    retry: "Yritä uudelleen",
    password_reset_link_invalid: "Virhe: Tämä salasanan palautuslinkki on vanhentunut tai ei kelpaa.",
    get_another_link: "Hanki toinen linkki"
  },
  [LanguageCodeFrench]: {
    error_app_load_failed: "Erreur : l'application n'a pas pu se charger.",
    retry: "Retenter",
    password_reset_link_invalid: "Erreur : Ce lien de réinitialisation de mot de passe a expiré ou n'est pas valide.",
    get_another_link: "Obtenez un autre lien"
  },
  [LanguageCodeCanadianFrench]: {
    error_app_load_failed: "Erreur : Le chargement de l'application a échoué.",
    retry: "Réessayer",
    password_reset_link_invalid: "Erreur : Ce lien de réinitialisation de mot de passe a expiré ou est invalide.",
    get_another_link: "Obtenir un autre lien"
  },
  [LanguageCodeGerman]: {
    error_app_load_failed: "Fehler: Das Laden der App ist fehlgeschlagen.",
    retry: "Versuchen Sie es noch einmal",
    password_reset_link_invalid: "Fehler: Dieser Link zum Zurücksetzen des Passworts ist abgelaufen oder ungültig.",
    get_another_link: "Holen Sie sich einen weiteren Link"
  },
  [LanguageCodeGreek]: {
    error_app_load_failed: "Σφάλμα: Η φόρτωση της εφαρμογής απέτυχε.",
    retry: "Ξαναδοκιμάσετε",
    password_reset_link_invalid: "Σφάλμα: Αυτός ο σύνδεσμος επαναφοράς κωδικού πρόσβασης έχει λήξει ή δεν είναι έγκυρος.",
    get_another_link: "Λάβετε άλλο σύνδεσμο"
  },
  [LanguageCodeEnglish]: {
    error_app_load_failed: "Error: App Loading Failed.",
    retry: "Retry",
    password_reset_link_invalid: "Error: This password reset link has expired or is invalid.",
    get_another_link: "Get another link"
  },
  [LanguageCodeHebrew]: {
    error_app_load_failed: "שגיאה: האפליקציה לא הצליחה להיטען.",
    retry: "נסה שוב",
    password_reset_link_invalid: "שגיאה: פג תוקף הקישור הזה לאיפוס סיסמה או שהוא לא חוקי.",
    get_another_link: "קבל קישור נוסף"
  },
  [LanguageCodeHindi]: {
    error_app_load_failed: "त्रुटि: ऐप लोड होने में विफल रहा।",
    retry: "पुन: प्रयास",
    password_reset_link_invalid: "त्रुटि: यह पासवर्ड रीसेट लिंक समाप्त हो गया है या अमान्य है।",
    get_another_link: "दूसरा लिंक प्राप्त करें"
  },
  [LanguageCodeHungarian]: {
    error_app_load_failed: "Hiba: Az alkalmazás betöltése sikertelen.",
    retry: "Próbálja újra",
    password_reset_link_invalid: "Hiba: Ez a jelszó-visszaállítási hivatkozás lejárt vagy érvénytelen.",
    get_another_link: "Szerezz másik linket"
  },
  [LanguageCodeIndonesian]: {
    error_app_load_failed: "Kesalahan: Aplikasi gagal dimuat.",
    retry: "Mencoba kembali",
    password_reset_link_invalid: "Kesalahan: Tautan pengaturan ulang kata sandi ini telah kedaluwarsa atau tidak valid.",
    get_another_link: "Dapatkan tautan lain"
  },
  [LanguageCodeItalian]: {
    error_app_load_failed: "Errore: l'app non è stata caricata.",
    retry: "Riprova",
    password_reset_link_invalid: "Errore: questo collegamento per la reimpostazione della password è scaduto o non è valido.",
    get_another_link: "Ottieni un altro collegamento"
  },
  [LanguageCodeJapanese]: {
    error_app_load_failed: "エラー: アプリの読み込みに失敗しました。",
    retry: "リトライ",
    password_reset_link_invalid: "エラー: このパスワード リセット リンクは有効期限が切れているか、無効です。",
    get_another_link: "別のリンクを取得する"
  },
  [LanguageCodeKorean]: {
    error_app_load_failed: "오류: 앱을 로드하지 못했습니다.",
    retry: "다시 해 보다",
    password_reset_link_invalid: "오류: 이 비밀번호 재설정 링크는 만료되었거나 유효하지 않습니다.",
    get_another_link: "다른 링크 받기"
  },
  [LanguageCodeMalay]: {
    error_app_load_failed: "Ralat: Apl gagal dimuatkan.",
    retry: "Cuba semula",
    password_reset_link_invalid: "Ralat: Pautan tetapan semula kata laluan ini telah tamat tempoh atau tidak sah.",
    get_another_link: "Dapatkan pautan lain"
  },
  [LanguageCodeNorwegian]: {
    error_app_load_failed: "Feil: Appen ble ikke lastet.",
    retry: "Prøv på nytt",
    password_reset_link_invalid: "Feil: Denne koblingen for tilbakestilling av passord er utløpt eller er ugyldig.",
    get_another_link: "Få en annen link"
  },
  [LanguageCodePolish]: {
    error_app_load_failed: "Błąd: Nie udało się załadować aplikacji.",
    retry: "Spróbować ponownie",
    password_reset_link_invalid: "Błąd: ten link do resetowania hasła wygasł lub jest nieprawidłowy.",
    get_another_link: "Zdobądź inny link"
  },
  [LanguageCodePortuguese]: {
    error_app_load_failed: "Erro: O aplicativo falhou ao carregar.",
    retry: "Tentar novamente",
    password_reset_link_invalid: "Erro: Este link de redefinição de senha expirou ou é inválido.",
    get_another_link: "Obtenha outro link"
  },
  [LanguageCodeBrazilianPortuguese]: {
    error_app_load_failed: "Erro: Falha ao carregar o aplicativo.",
    retry: "Tentar novamente",
    password_reset_link_invalid: "Erro: Este link para redefinição de senha expirou ou é inválido.",
    get_another_link: "Obter outro link"
  },
  [LanguageCodeRomanian]: {
    error_app_load_failed: "Eroare: aplicația nu s-a încărcat.",
    retry: "Reîncercați",
    password_reset_link_invalid: "Eroare: acest link de resetare a parolei a expirat sau este invalid.",
    get_another_link: "Obțineți un alt link"
  },
  [LanguageCodeRussian]: {
    error_app_load_failed: "Ошибка: Не удалось загрузить приложение.",
    retry: "Попробуй еще раз",
    password_reset_link_invalid: "Ошибка: срок действия этой ссылки для сброса пароля истек или она недействительна.",
    get_another_link: "Получить другую ссылку"
  },
  [LanguageCodeSlovak]: {
    error_app_load_failed: "Chyba: Aplikáciu sa nepodarilo načítať.",
    retry: "Skúste znova",
    password_reset_link_invalid: "Chyba: Platnosť tohto odkazu na obnovenie hesla vypršala alebo je neplatný.",
    get_another_link: "Získajte ďalší odkaz"
  },
  [LanguageCodeSpanish]: {
    error_app_load_failed: "Error: la aplicación no se pudo cargar.",
    retry: "Intentar otra vez",
    password_reset_link_invalid: "Error: este enlace para restablecer contraseña ha caducado o no es válido.",
    get_another_link: "Obtener otro enlace"
  },
  [LanguageCodeMexicanSpanish]: {
    error_app_load_failed: "Error: La aplicación no se pudo cargar.",
    retry: "Reintentar",
    password_reset_link_invalid: "Error: Este enlace para restablecer la contraseña ha expirado o es inválido.",
    get_another_link: "Obtener otro enlace"
  },
  [LanguageCodeSwedish]: {
    error_app_load_failed: "Fel: Appen kunde inte laddas.",
    retry: "Försök igen",
    password_reset_link_invalid: "Fel: Denna länk för återställning av lösenord har upphört att gälla eller är ogiltig.",
    get_another_link: "Skaffa en annan länk"
  },
  [LanguageCodeThai]: {
    error_app_load_failed: "ข้อผิดพลาด: ไม่สามารถโหลดแอปได้",
    retry: "ลองอีกครั้ง",
    password_reset_link_invalid: "ข้อผิดพลาด: ลิงก์รีเซ็ตรหัสผ่านนี้หมดอายุหรือไม่ถูกต้อง",
    get_another_link: "รับลิงค์อื่น"
  },
  [LanguageCodeTurkish]: {
    error_app_load_failed: "Hata: Uygulama başlatılamadı",
    retry: "Tekrar deneyin",
    password_reset_link_invalid: "Hata: Bu şifre sıfırlama bağlantısının süresi dolmuş veya geçersiz.",
    get_another_link: "Başka bir bağlantı al"
  },
  [LanguageCodeUkranian]: {
    error_app_load_failed: "Помилка: не вдалося завантажити програму.",
    retry: "Спробуйте знову",
    password_reset_link_invalid: "Помилка: термін дії цього посилання для скидання пароля закінчився або воно недійсне.",
    get_another_link: "Отримайте інше посилання"
  },
  [LanguageCodeVietnamese]: {
    error_app_load_failed: "Lỗi: Không tải được ứng dụng.",
    retry: "Thử lại",
    password_reset_link_invalid: "Lỗi: Liên kết đặt lại mật khẩu này đã hết hạn hoặc không hợp lệ.",
    get_another_link: "Nhận một liên kết khác"
  }
}

export const getLocalizedErrorMessageFromRequestResult = (req) => {
  return !!(req.error) ? (i18next.t(req.error.error_code) || req.error.error_message) : ""
}

export const generateLocalizedInsightString = (pctCheckInGain, pctHrvGain, pctBalanceScoreGain, insightCount, resultInsightFlags) => {
  const check_in_pct = formatPercentage(pctCheckInGain)
  const hrv_pct = formatPercentage(pctHrvGain)
  const balance_pct = formatPercentage(pctBalanceScoreGain)
  if (insightCount === 3) {
    return i18next.t('key_takeaways_all', {check_in_pct, hrv_pct, balance_pct})
  } else if (insightCount === 2) {
    if (!(resultInsightFlags["check_in"])) {
      return i18next.t('key_takeaways_hrv_balance', {hrv_pct, balance_pct})
    } else if (!resultInsightFlags["hrv"]) {
      return i18next.t('key_takeaways_check_in_balance', {check_in_pct, balance_pct})
    } else {
      return i18next.t('key_takeaways_check_in_hrv', {check_in_pct, hrv_pct})
    }
  } else if (insightCount === 1) {
    if ((resultInsightFlags["check_in"])) {
      return i18next.t('key_takeaways_check_in', {check_in_pct})
    } else if (resultInsightFlags["hrv"]) {
      return i18next.t('key_takeaways_hrv', {hrv_pct})
    } else {
      return i18next.t('key_takeaways_balance', {balance_pct})
    }
  }
}

export const formatPercentage = (number, decimalPlaces=0) => {
  return new Intl.NumberFormat(i18next.languages, {style: "percent", minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces}).format(number);
}

export const formatUnitNumber = (number, unitName, decimalPlaces=0, unitDisplay="short") => {
  try {
    return new Intl.NumberFormat(i18next.languages, {style: "unit", unit: unitName, unitDisplay, minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces}).format(number);
  } catch (err) {
    return new Intl.NumberFormat(i18next.languages, {style: "decimal", minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces}).format(number) + " " + unitName
  }
}

export const formatNumber = (number, decimalPlaces=0) => {
  return new Intl.NumberFormat(i18next.languages, {style: "decimal", minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces}).format(number)
}

export const formatAbbreviatedNumber = (number) => {
  const decimalPlaces = number > 1000 ? 1 : 0
  return new Intl.NumberFormat(i18next.languages, {style: "decimal", notation: "compact", compactDisplay: "short", minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces}).format(number)
}

export const formatCurrency = (number, currencyCode) => {
  try {
    return new Intl.NumberFormat(i18next.languages, {style: "currency", currency: currencyCode, currencyDisplay: "narrowSymbol"}).format(number);
  } catch (err) {
    // iOS 14 Doesn't support narrowSymbol
    return new Intl.NumberFormat(i18next.languages, {style: "currency", currency: currencyCode, currencyDisplay: "symbol"}).format(number);
  }
}

export const formatDateFromTimestamp = (timestampSeconds, options={month: "short", year: "numeric", day: "numeric"}) => {
  return new Intl.DateTimeFormat(i18next.languages, options).format(new Date(timestampSeconds * 1000))
}


// TODO: Localize.
export const formatLocalizedContentDuration = (durationSeconds) => {
  if (durationSeconds < 0) {
    return "0:00"
  }
  const seconds = (durationSeconds % 60)
  return Math.floor(durationSeconds / 60) + ":" + ((seconds < 10) ? "0" : "") + seconds
}

export const getLocalizedPublicArticleUrl = (languageCode, articleKey) => `${ProductionBaseUrl}/learn/${languageCode}/${articleKey}/`

export const getLocalizedPublicBalanceExerciseUrl = (languageCode, exerciseKey) => `${ProductionBaseUrl}/exercise/${languageCode}/${exerciseKey}/`

export const isRtlLanguage = () => {
  return (!!(i18next.language) && (i18next.dir() === "rtl"))
}

export const getLocalizedGlossaryPageUrlForLanguageCode = languageCode => `${ProductionGlossaryBaseUrl}/${languageCode}`

const HrefLangRelAlternate = "alternate"
const HrefLangRelXDefault = "x-default"

export const getHreflangTagForLanguageCode = (languageCode, isDefault=false) => (
  <link href={getLocalizedGlossaryPageUrlForLanguageCode(languageCode)} hrefLang={languageCode} rel={isDefault ? HrefLangRelXDefault : HrefLangRelAlternate} key={languageCode} />
)
