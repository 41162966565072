import React from 'react'

import {
  NeuroFitUniversalAppStoreLink,
  copyToClipboard,
} from '../../utils'

import MembershipFeatureDescription from '../common/MembershipFeatureDescription'
import DownloadButtonsSection from '../common/DownloadButtonsSection'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


class AppPaymentResultModal extends React.Component {

  constructor(props){
     super(props)

     this.state = {
      disableEmailCopyButton: false,
      emailIsCopied: false
     }
  }

  render() {

    const {
      // State
      show,
      firstName,
      emailAddress,
      safeAreaInsets,
      innerHeight,
      isSuccessful,
      isIOSDevice,
      isAndroidDevice,
      isDesktop,
      onClose,
    } = this.props

    const {
      disableEmailCopyButton,
      emailIsCopied
    } = this.state

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="redeem-access-code-modal"
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: safeAreaInsets.top,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: show ? "rgba(0, 0, 0, 0.2)" : "transparent",
            backdropFilter: show ? "blur(4px)" : "none",
            WebkitBackdropFilter: show ? "blur(4px)" : "none",
            zIndex: 1000000
          }}
          onClick={e => {
            if (!isSuccessful) {
              e.stopPropagation()
              onClose()
            }
          }}
        >
          <div 
            id={"redeem-app-access-modal"}
            style={{
              position: 'absolute',
              background: 'white',
              border: "2px black solid",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              width: "calc(95% - 4px)",
              maxWidth: 600,
              left: 0,
              right: 0,
              margin: "0px auto",
              top: (safeAreaInsets.top) / 2 + (innerHeight / 4),
              maxHeight: Math.min(600, (innerHeight - safeAreaInsets.top) / 1.5),
            }}
            onClick={e => e.stopPropagation()}
          >
            <div style={{color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              {!isSuccessful && (
                <span
                  className="close"
                  onClick={() => {
                    onClose()
                  }}
                >
                  &times;
                </span>
              )}
              <div style={{width: "85%", color: "#000000", height: "100%", padding: "min(3vh, 20px) 0px"}}>
                {isSuccessful ? (
                  <div style={{paddingBottom: "3vh"}}>
                    <div style={{fontSize: "min(6vw, 30px)", marginTop: "4vh", textTransform: "uppercase", fontFamily: "Futura"}}>
                      {`Welcome To NEUROFIT ⚡`}
                    </div>
                    <div style={{marginTop: "1vh", padding: "min(2vw, 10px) 0px", fontSize: "min(5vw, 22px)"}}>
                      <div style={{fontFamily: "Futura Book"}}>
                        {`Thanks for joining us, ${firstName}! Better wellbeing starts now. 🔋`}
                      </div>
                      <div style={{fontFamily: "Futura Light", fontFamily: "Futura Book", marginTop: "3vh"}}>
                        {"Download the App, then Log In with your email address:"}
                      </div>
                      <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                        <div
                          disabled={disableEmailCopyButton}
                          style={{overflow: "hidden", marginTop: "3vh", display: "block", width: "min(100%, 350px)", userSelect: "auto", cursor: disableEmailCopyButton ? "not-allowed" : "pointer", border: "none", textAlign: "center", WebkitUserSelect: "auto", textOverflow: "ellipsis", whiteSpace: "nowrap", lineHeight: "18px", backgroundColor: "#000000", color: disableEmailCopyButton ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF", fontSize: "18px", lineHeight: "18px", fontFamily: "Futura", padding: "12px 18px"}}
                          onClick={() => {
                            this.setState({emailIsCopied: true})
                            navigator.clipboard.writeText(emailAddress)
                            setTimeout(() => {
                              this.setState({emailIsCopied: false})
                            }, 1000)
                          }}
                        >
                          {emailIsCopied ? "COPIED EMAIL ⚡" : emailAddress}
                        </div>
                      </div>
                      <div
                        style={{fontFamily: "Futura Medium", marginTop: "1vh", textAlign: "center", fontSize: "15px", cursor: disableEmailCopyButton ? "not-allowed" : "pointer", lineHeight: "18px", color: "#999999", border: "none"}}
                        disabled={disableEmailCopyButton}
                        onClick={() => {
                          this.setState({emailIsCopied: true})
                          navigator.clipboard.writeText(emailAddress)
                          setTimeout(() => {
                            this.setState({emailIsCopied: false})
                          }, 1000)
                        }}
                      >
                        {isDesktop ? "CLICK TO COPY" : "TAP TO COPY"}
                      </div>
                    </div>
                    <DownloadButtonsSection
                      isIOSDevice={isIOSDevice}
                      isAndroidDevice={isAndroidDevice}
                      onShowQrCodeModal={() => window.open(NeuroFitUniversalAppStoreLink)}
                    />
                  </div>
                ) : (
                  <div style={{width: "100%", height: "100%", color: "#000", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                    <div>
                      <div style={{fontSize: "min(6vw, 26px)", textTransform: "uppercase", fontFamily: "Futura"}}>
                        {"UPDATE EMAIL ADDRESS"}
                      </div>
                      <div style={{marginTop: 15, marginBottom: 15, fontSize: "min(5.5vw, 22px)", textAlign: "start", fontFamily: "Futura Light"}}>
                        <div style={{display: "inline-block"}}>
                          {"We weren't able to grant access to the email address you entered - please check for typos and try again."}
                        </div>
                      </div>
                    </div>
                    <button
                      style={{display: "block", textTransform: "uppercase", marginTop: "10vh", width: "100%", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                      onClick={() => {
                        onClose()
                      }}
                    >
                      {"CLOSE"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default AppPaymentResultModal;